import React from "react";
import { Route, RouteProps } from "react-router-dom";

// components
import PrivateRoute from "./PrivateRoute";

// root
const Root = React.lazy(() => import("./Root"));

// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const Confirm = React.lazy(() => import("../pages/auth/Confirm"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));
const LockScreen = React.lazy(() => import("../pages/auth/LockScreen"));

// dashboard
const EcommerceDashboard = React.lazy(
	() => import("../pages/dashboard/Ecommerce"),
);
const AnalyticsDashboard = React.lazy(
	() => import("../pages/dashboard/Analytics"),
);

// apps
// awb
const AwbDetail = React.lazy(() => import("../pages/awb/Detail/index"));
const AwbList = React.lazy(() => import("../pages/awb/index"));
const AwbEdit = React.lazy(() => import("../pages/awb/Edit"));
// tracking
const TrackingDetail = React.lazy(
	() => import("../pages/tracking/Detail/index"),
);
const TrackingList = React.lazy(() => import("../pages/tracking/index"));
const TrackingEdit = React.lazy(() => import("../pages/tracking/Edit"));
const TrackingSearch = React.lazy(
	() => import("../pages/tracking/SearchTracking"),
);

// DeliverySlip
const DeliverySlip = React.lazy(() => import("../pages/delivery-slip/index"));
const CreateVouchers = React.lazy(
	() => import("../pages/delivery-slip/CreateVouchers"),
);
const CreateDeliverySlip = React.lazy(
	() => import("../pages/delivery-slip/CreateDeliverySlip"),
);
const VoteConfirm = React.lazy(
	() => import("../pages/delivery-slip/VoteConfirm"),
);
const PrintCoupons = React.lazy(
	() => import("../pages/delivery-slip/PrintCoupons"),
);
const EditDeliverySlip = React.lazy(
	() => import("../pages/delivery-slip/EditDeliverySlip"),
);

// order
const OrderDetail = React.lazy(() => import("../pages/order/Detail/index"));
const OrderList = React.lazy(() => import("../pages/order/index"));
const OrderEdit = React.lazy(() => import("../pages/order/Edit"));
// customer
const CustomerDetail = React.lazy(
	() => import("../pages/customers/Detail/index"),
);
const CustomerList = React.lazy(() => import("../pages/customers/index"));
const CustomerEdit = React.lazy(() => import("../pages/customers/Edit"));
const CustomerCreate = React.lazy(
	() => import("../pages/customers/AddCustomer"),
);
const InforUserLogged = React.lazy(
	() => import("../pages/customers/InforUserLogged"),
);
// employee
const EmployeeDetail = React.lazy(() => import("../pages/employee/View"));
const EmployeeList = React.lazy(() => import("../pages/employee/index"));
const EmployeeEdit = React.lazy(() => import("../pages/employee/Edit"));
const EmployeeCreate = React.lazy(
	() => import("../pages/employee/AddEmployee"),
);
// transaction
const TransactionDetail = React.lazy(
	() => import("../pages/transaction/Detail/index"),
);
const TransactionList = React.lazy(() => import("../pages/transaction/index"));
const TransactionEdit = React.lazy(() => import("../pages/transaction/Edit"));
// setting
const SettingDetail = React.lazy(() => import("../pages/setting/Detail/index"));
const SettingList = React.lazy(() => import("../pages/setting/index"));
const SettingEdit = React.lazy(() => import("../pages/setting/Edit"));

// extra pages
const Error404 = React.lazy(() => import("../pages/error/Error404"));
const Error500 = React.lazy(() => import("../pages/error/Error500"));

export interface RoutesProps {
	path: RouteProps["path"];
	name?: string;
	component?: any;
	route?: any;
	exact?: RouteProps["exact"];
	icon?: string;
	header?: string;
	roles?: string[];
	children?: RoutesProps[];
}

// root routes
const rootRoute: RoutesProps = {
	path: "/",
	exact: true,
	component: Root,
	route: Route,
};

// dashboards
const dashboardRoutes: RoutesProps = {
	path: "/dashboard",
	name: "Dashboards",
	icon: "airplay",
	header: "Navigation",
	children: [
		{
			path: "/dashboard/ecommerce",
			name: "Ecommerce",
			component: EcommerceDashboard,
			route: PrivateRoute,
		},
		{
			path: "/dashboard/analytics",
			name: "Analytics",
			component: AnalyticsDashboard,
			route: PrivateRoute,
		},
	],
};

// app
const projectAppRoutes: RoutesProps[] = [
	{
		path: "/mawb",
		name: "Awb",
		route: PrivateRoute,
		roles: ["Admin"],
		icon: "uil-briefcase",

		children: [
			{
				path: "/mawb",
				name: "Awb List",
				component: AwbList,
				route: PrivateRoute,
			},
			{
				path: "/mawb/:id",
				name: "Awb Detail",
				component: AwbDetail,
				route: PrivateRoute,
			},
			{
				path: "/mawb/edit/:id",
				name: "Awb Detail",
				component: AwbEdit,
				route: PrivateRoute,
			},
		],
	},
	{
		path: "/tracking",
		name: "Tracking",
		route: PrivateRoute,
		roles: ["Admin"],
		icon: "uil-briefcase",

		children: [
			{
				path: "/tracking/search",
				name: "Tracking Search",
				component: TrackingSearch,
				route: PrivateRoute,
			},
			{
				path: "/tracking",
				name: "Tracking List",
				component: TrackingList,
				route: PrivateRoute,
			},
			{
				path: "/tracking/:id",
				name: "Tracking Detail",
				component: TrackingDetail,
				route: PrivateRoute,
			},
			{
				path: "/tracking/edit/:id",
				name: "Tracking Detail",
				component: TrackingEdit,
				route: PrivateRoute,
			},
		],
	},
	{
		path: "/deliveryslip",
		name: "Delivery-slip",
		route: PrivateRoute,
		roles: ["Admin"],
		icon: "uil-briefcase",

		children: [
			{
				path: "/deliveryslip/print-coupons",
				name: "PrintCoupons",
				component: PrintCoupons,
				route: PrivateRoute,
			},
			{
				path: "/deliveryslip/edit-delivery-slip",
				name: "EditDeliverySlip",
				component: EditDeliverySlip,
				route: PrivateRoute,
			},
			{
				path: "/deliveryslip/print-coupons",
				name: "PrintCoupons",
				component: PrintCoupons,
				route: PrivateRoute,
			},
			{
				path: "/deliveryslip/vote-confirm",
				name: "VoteConfirm",
				component: VoteConfirm,
				route: PrivateRoute,
			},
			{
				path: "/deliveryslip/create-vouchers",
				name: "CreateVouchers",
				component: CreateVouchers,
				route: PrivateRoute,
			},
			{
				path: "/deliveryslip/create-delivery-slip",
				name: "CreateDeliverySlip",
				component: CreateDeliverySlip,
				route: PrivateRoute,
			},

			{
				path: "/deliveryslip",
				name: "Delivery-slip List",
				component: DeliverySlip,
				route: PrivateRoute,
			},
		],
	},
	{
		path: "/order",
		name: "Order",
		route: PrivateRoute,
		roles: ["Admin"],
		icon: "uil-briefcase",

		children: [
			{
				path: "/order/edit/:id",
				name: "Order Edit Detail",
				component: OrderEdit,
				route: PrivateRoute,
			},
			{
				path: "/order/:id",
				name: "Order Detail",
				component: OrderDetail,
				route: PrivateRoute,
			},
			{
				path: "/order",
				name: "Order List",
				component: OrderList,
				route: PrivateRoute,
			},
		],
	},
	{
		path: "/customer",
		name: "Customer",
		route: PrivateRoute,
		roles: ["Admin"],
		icon: "uil-briefcase",

		children: [
			{
				path: "/customer/infor-user",
				name: "Infor User Logged",
				component: InforUserLogged,
				route: PrivateRoute,
			},
			{
				path: "/customer/create",
				name: "Customer Create",
				component: CustomerCreate,
				route: PrivateRoute,
			},
			{
				path: "/customer/edit/:id",
				name: "Customer Detail",
				component: CustomerEdit,
				route: PrivateRoute,
			},
			{
				path: "/customer/:id",
				name: "Customer Detail",
				component: CustomerDetail,
				route: PrivateRoute,
			},
			{
				path: "/customer",
				name: "Customer List",
				component: CustomerList,
				route: PrivateRoute,
			},
		],
	},
	{
		path: "/employee",
		name: "Employee",
		route: PrivateRoute,
		roles: ["Admin"],
		icon: "uil-briefcase",

		children: [
			{
				path: "/employee/create",
				name: "Employee Create",
				component: EmployeeCreate,
				route: PrivateRoute,
			},
			{
				path: "/employee/edit/:id",
				name: "Employee Edit",
				component: EmployeeEdit,
				route: PrivateRoute,
			},
			{
				path: "/employee/:id",
				name: "Employee Detail",
				component: EmployeeDetail,
				route: PrivateRoute,
			},
			{
				path: "/employee",
				name: "Employee List",
				component: EmployeeList,
				route: PrivateRoute,
			},
		],
	},
	{
		path: "/transaction",
		name: "Transaction",
		route: PrivateRoute,
		roles: ["Admin"],
		icon: "uil-briefcase",

		children: [
			{
				path: "/transaction",
				name: "Transaction List",
				component: TransactionList,
				route: PrivateRoute,
			},
			{
				path: "/transaction/:id",
				name: "Transaction Detail",
				component: TransactionDetail,
				route: PrivateRoute,
			},
			{
				path: "/transaction/edit/:id",
				name: "Transaction Detail",
				component: TransactionEdit,
				route: PrivateRoute,
			},
		],
	},
	{
		path: "/setting",
		name: "Setting",
		route: PrivateRoute,
		roles: ["Admin"],
		icon: "uil-briefcase",

		children: [
			{
				path: "/setting",
				name: "Setting List",
				component: SettingList,
				route: PrivateRoute,
			},
			{
				path: "/setting/:id",
				name: "Setting Detail",
				component: SettingDetail,
				route: PrivateRoute,
			},
			{
				path: "/setting/edit/:id",
				name: "Setting Detail",
				component: SettingEdit,
				route: PrivateRoute,
			},
		],
	},
];

// auth
const authRoutes: RoutesProps[] = [
	{
		path: "/auth/login",
		exact: true,
		name: "Login",
		component: Login,
		route: Route,
	},
	{
		path: "/auth/confirm",
		name: "Confirm",
		component: Confirm,
		route: Route,
	},
	{
		path: "/auth/forget-password",
		name: "Forget Password",
		component: ForgetPassword,
		route: Route,
	},
	{
		path: "/auth/lock-screen",
		name: "Lock Screen",
		component: LockScreen,
		route: Route,
	},
	{
		path: "/auth/logout",
		name: "Logout",
		component: Logout,
		route: Route,
	},
];

// public routes
const otherPublicRoutes: RoutesProps[] = [
	{
		path: "/error-404",
		name: "Error - 404",
		component: Error404,
		route: Route,
	},
	{
		path: "/error-500",
		name: "Error - 500",
		component: Error500,
		route: Route,
	},
];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
	let flatRoutes: RoutesProps[] = [];

	routes = routes || [];
	routes.forEach((item: RoutesProps) => {
		flatRoutes.push(item);

		if (typeof item.children !== "undefined") {
			flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
		}
	});
	return flatRoutes;
};

// All routes
const authProtectedRoutes = [rootRoute, dashboardRoutes, ...projectAppRoutes];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export {
	publicRoutes,
	authProtectedRoutes,
	authProtectedFlattenRoutes,
	publicProtectedFlattenRoutes,
};
